import React from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import {Helmet} from 'react-helmet';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import { scroller } from 'react-scroll';

import MyAppBar from '../../components/Header/MyAppBar';

import Home from '../Home/Home';
import UpdateGear from '../UpdateGear/UpdateGear';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import {createTheme, ThemeProvider} from '@material-ui/core/styles';
import AdminUpdateGear from '../Admin/UpdateGear';

import './App.scss'

const provider = new firebase.auth.GoogleAuthProvider();

provider.setCustomParameters({
	prompt: 'select_account'
});

class App extends React.PureComponent {
	constructor(props) {
		super(props);

		this.login = this.login.bind(this);
		this.logout = this.logout.bind(this);
		this.toggleExpanded = this.toggleExpanded.bind(this);

		this.state = {
			user: null,
			allycode: '',
			isExpanded: false
		}

	}

	componentDidMount() {
		const that = this;

		firebase.auth().onAuthStateChanged((user) => {
			if (user) {
				this.setState({user: user})
			}
		})
		firebase.auth().getRedirectResult().then(function (result) {
			const user = result.user;
			that.setState({user: user});
		}).catch(function (error) {
			console.error(error);
		});
	}

	login() {
		firebase.auth().signInWithPopup(provider);
	}

	logout() {
		let that = this;
		firebase.auth().signOut().then((result) => {
			that.setState({
				user: null
			});
		});
	}


	scrollTo() {
		scroller.scrollTo('gear', {
			duration: 800,
			delay: 0,
			smooth: 'easeInOutQuart'
		})
	}

	handleInput = e => {
		this.setState({
			allycode: e.target.value
		})
	}
	changeAllyCode = e => {
		e.preventDefault();
		console.log(e.target[0].value)
		this.setState({
			currentAllyCode: e.target[0].value
		})
	}

	toggleExpanded() {
		this.setState({
			isExpanded: !this.state.isExpanded
		})
	}

	render() {
		const theme = createTheme({
			typography: {
				fontFamily: [
					'Open Sans',
					'Roboto',
					'Arial',
					'sans-serif',
				].join(','),
			},
		});

		return (
			<div className={'container'}>
				<Helmet
					defaultTitle="SWGOH Events Gear"
					titleTemplate="%s – SWGOH Events Gear"
					link={[{
						rel: 'icon',
						sizes: '16x16',
						type: 'image/ico',
						href: ('../img/favicon.ico')
					}]}
				/>
				<Router>
					<MuiThemeProvider>
						<ThemeProvider theme={theme}>
							<MyAppBar user={this.state.user} login={this.login} logout={this.logout} goToGear={() => this.scrollTo()}/>
							<Route exact path="/"
								   render={(props) => <Home {...props} firebaseUser={this.state.user} allycode={this.state.currentAllyCode} isExpanded={this.state.isExpanded} toggleExpanded={this.toggleExpanded}/>}/>
							<Route exact path="/updateGear"
								   render={() => <UpdateGear firebaseUser={this.state.user}/>}/>
							<Route exact path="/c/:character"
								   render={(props) => <Home {...props} character={props.match.params.character} firebaseUser={this.state.user}/>}/>
							<Route exact path="/a/updateGear" component={AdminUpdateGear}/>
						</ThemeProvider>
					</MuiThemeProvider>
				</Router>
			</div>
		);
	}
}

export default App;
