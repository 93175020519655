import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';

import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHomeAlt, faSignInAlt, faSignOutAlt, faUserCog, faWarehouseAlt} from "@fortawesome/pro-duotone-svg-icons";

import './MyAppBar.scss';


const styles = {
	menuButton: {
		marginLeft: -12,
		marginRight: 20,
	},
};


function MyAppBar(props) {

	let authButton = props.user ?
		<Button className={'menuBtn'} onClick={props.logout}>
			<FontAwesomeIcon icon={faSignOutAlt}/>
			<span className={'txt'}>
				Logout
			</span>
		</Button> :
		<Button className={'menuBtn'} onClick={props.login}>
			<FontAwesomeIcon icon={faSignInAlt}/>
			<span className={'txt'}>
				Login with Google
			</span>
		</Button>

	return (
		<div className={'header-top'}>
			<AppBar position="static">
				<Toolbar>
					<div className={'title'}>
						<div className={'logo'}/>
						<span className={'txt'}>
							SWGOH EVENTS: GEAR
            			</span>
					</div>
					<div className={'right-aside'}>
						<Link to="/">
							<Button className={'menuBtn'}>
								<FontAwesomeIcon icon={faHomeAlt}/>
								<span className={'txt'}>
									Home
								</span>
							</Button>
						</Link>
						<Link to={'/'} onClick={props.goToGear}>
							<Button className={'menuBtn'}>
								<FontAwesomeIcon icon={faUserCog}/>
								<span className={'txt'}>
									Go To Gear Table
								</span>
							</Button>
						</Link>
						<Link to="/updateGear">
							<Button className={'menuBtn'}>
								<FontAwesomeIcon icon={faWarehouseAlt}/>
								<span className={'txt'}>
									View/Update Gear
								</span>
							</Button>
						</Link>
						{authButton}
					</div>
				</Toolbar>

			</AppBar>
		</div>
	);
}

export default withStyles(styles)(MyAppBar);