import React, {Component} from "react";
import Carousel from 'react-material-ui-carousel'
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";

import "./GearTable.scss";
import "../../styles/modules/checkbox.scss"
import {faAngleRight, faAngleLeft} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class Presets extends Component {
	handleLegendaryChange = (legendary, e) => {
		this.props.showLegendary(legendary, e.target.checked);
	};

	componentDidUpdate(prevProps) {
		if (this.props.LegendaryRequirements && this.props.selectedLegendaries) {
			if (this.props.LegendaryRequirements !== prevProps.LegendaryRequirements) {
				this.props.LegendaryRequirements.forEach(legendary => {
					if (this.props.selectedLegendaries.find(x => x.value === legendary.value)) {
						this.props.showLegendary(legendary, true);
					}
				});
			}
		}
	}

	render() {
		const legendary = this.props.LegendaryRequirements && this.props.LegendaryRequirements;
		let items: Array<any> = [];

		if(legendary) {
			const sliderItems: number = window.screen.width > "1000" ? 5 : window.screen.width > "600" ? 3 : 2;

			for (let i = 0; i < legendary.length; i += sliderItems) {
				if (i % sliderItems === 0) {
					items.push(
						legendary.slice(i, i + sliderItems).map(legendary => {
							return <FormControlLabel
								key={legendary.value}
								className={'custom-cbox'}
								control={
									<Checkbox
										checked={this.props.selectedLegendaries && !!this.props.selectedLegendaries.find(x => x.value === legendary.value)}
										onChange={this.handleLegendaryChange.bind(this, legendary)}
										color="primary"
									/>
								}
								label={
									<div className={'label'}>
										<img className={'preset-images'} src={'../img/presets/' + legendary.value + '.png'}/>
										<div className={'txt'}>
											{legendary.label}
										</div>
									</div>
								}
							/>;
						})
					);
				}
			}
		}

		return (
			<div className={'right-side'}>
				<div className={'title'}>
					Select a preset to view the required characters automatically:
				</div>
				<FormGroup className={'container-presets'}>

					{items ?
						<Carousel
							NextIcon={<FontAwesomeIcon icon={faAngleRight}/>}
							PrevIcon={<FontAwesomeIcon icon={faAngleLeft}/>}
							autoPlay={false}
							navButtonsAlwaysVisible={true}
							animation={"slide"}
							className={'carousel'}
							navButtonsProps={{
								style: {
									width: '20px',
									height: '20px',
									fontSize: '16px',
									backgroundColor: 'SlateGrey',
								}
							}}
						>
							{items}
						</Carousel>
						: null
					}

				</FormGroup>
			</div>
		);
	}
}

export default Presets;
