import React, {PureComponent} from "react";
import Modal from "@material-ui/core/Modal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/pro-solid-svg-icons";
import Select from "react-select";
import {Button} from "@material-ui/core";
import * as PropTypes from "prop-types";
import "./ModalCharacter.scss";

export class ModalCharacter extends PureComponent {
	selectRef = React.createRef()

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.open !== this.props.open && this.props.open) {
			setTimeout(() => {
				this.selectRef.current && this.selectRef.current.focus();
			}, 200);
		}
	}

	render() {
		return <Modal
			open={this.props.open}
			onClose={this.props.onClose}
			aria-labelledby="Add/Edit a character"
			aria-describedby="You can add a new character and choose the relic, rarity and the gear"
		>
			<div className={this.props.classes.paper + " modal"}>
				<header className={"modal-header"}>
					<h1 id="dialog-1-title" className="h1">{this.props.isEditing ? 'Add a new' : 'Edit the'} character</h1>
					<button aria-label="close" type="button" className={"close"} onClick={this.props.onClose}>
						<FontAwesomeIcon icon={faTimes}/>
					</button>
				</header>

				<div className={"modal-body"}>
					<div className={"row"}>
						<div className={"column"}>
							<div className={"title"}>
								Search for a unit:
							</div>
							<Select
								value={this.props.value}
								options={this.props.options}
								onChange={this.props.onChange}
								className="select-unit"
								placeholder="Select a character"
								getOptionLabel={this.props.optionLabel}
								getOptionValue={this.props.optionValue}
								ref={this.selectRef}
							/>
						</div>
					</div>
					<div className={"row"}>
						<div className={"column"}>
							<div className={"title"}>
								Gear:
							</div>
							<Select
								defaultValue={{
									label: this.props.label,
									value: this.props.label
								}}
								options={this.props.numbers.map(this.props.callbackfn)}
								onChange={this.props.onChange1}
								className="select-unit"
								placeholder="Select gear level"
							/>
						</div>
						<div className={"column"}>
							<div className={"title"}>
								Relic:
							</div>
							<Select
								defaultValue={{
									label: this.props.label1,
									value: this.props.label1
								}}
								options={this.props.numbers1.map(this.props.callbackfn)}
								onChange={this.props.onChange2}
								className="select-unit"
								placeholder="Select relic level"
							/>
						</div>
					</div>
				</div>

				<footer className={"modal-footer"}>
					<Button aria-disabled="false" className={"secondary-btn"} onClick={this.props.onClick}>
						<span className={"txt"}>{this.props.isEditing ? 'Cancel' : 'Delete'}</span>
					</Button>
					<Button aria-disabled="false" className={"primary-btn"} onClick={this.props.onClick1}>
						<span className={"txt"}>{this.props.isEditing ? 'Add' : 'Save'}</span>
					</Button>
				</footer>
			</div>
		</Modal>;
	}
}

ModalCharacter.propTypes = {
	open: PropTypes.any,
	onClose: PropTypes.func,
	classes: PropTypes.any,
	value: PropTypes.any,
	options: PropTypes.any,
	onChange: PropTypes.func,
	optionLabel: PropTypes.func,
	optionValue: PropTypes.func,
	label: PropTypes.any,
	numbers: PropTypes.arrayOf(PropTypes.number),
	callbackfn: PropTypes.func,
	onChange1: PropTypes.func,
	label1: PropTypes.any,
	numbers1: PropTypes.arrayOf(PropTypes.number),
	onChange2: PropTypes.func,
	onClick: PropTypes.func,
	onClick1: PropTypes.func,
	isEditing: PropTypes.bool
};