import React, { Component } from 'react';

import base from '../../rebase';
import axios from 'axios';


class App extends Component {
  componentDidMount() {
    //import units
    axios.get('/json/unitsList.json')
      .then(function(response) {
        for (var x in response.data) {
          console.log(response.data[x])
          if (response.data[x].combatType === 1 && response.data[x].obtainable && response.data[x].rarity === 7 && response.data[x].obtainableTime === "0") {
            const charData = {
              "id": response.data[x].id,
              "baseId": response.data[x].baseId,
              "unitTier": response.data[x].unitTier,
              "thumbnailName": response.data[x].thumbnailName
            };
            console.log(charData)
            // console.log(response.data[x].id);
            base.post('units/'+ response.data[x].id, {
                data: charData
            });
          }
          
        }
      });
  }
  render() {
    return (
      <div className="App">
        Updating Gear
      </div>
    );
  }
}

export default App;
