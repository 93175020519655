import React, { Component } from "react";
import "./UpdateGear.scss";

import axios from "axios";

import base from "../../rebase";

import { withStyles } from "@material-ui/core/styles";

import TextField from "@material-ui/core/TextField";
import StarBorderOutlined from "@material-ui/icons/StarBorderOutlined";
import StarOutlined from "@material-ui/icons/StarOutlined";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationTriangle} from "@fortawesome/pro-duotone-svg-icons";
import { faTruckLoading } from "@fortawesome/pro-solid-svg-icons";

const cloneDeep = require("lodash.clonedeep");

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
});

class UpdateGear extends Component {
  constructor() {
    super();
    this.state = {
      equipment: [],
      equipmentOut: [],
      userGear: [],
      userFavorites: [],
      showFavorites: false,
      nameFilter: "",
      loading: true,
      loggedIn: false,
      premiumCurrency: [],
    };
    this.doSync = this.doSync.bind(this);
    this.DisplayTable = this.DisplayTable.bind(this);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.firebaseUser !== this.props.firebaseUser) {
      this.doSync();
    }
  }
  componentDidMount() {
    if (localStorage.getItem("showFavorites")) {
      this.setState({
        showFavorites: JSON.parse(localStorage.getItem("showFavorites")),
      });
    }

    if (this.props.firebaseUser) {
      this.setState({loggedIn: true});
      this.doSync();
    }

    axios.get("/json/equipmentList.json").then((response) => {
      var equipment = [];
      var data = response.data;
      for (var x in data) {
        if (
          data[x].id !== "9999" &&
          data[x].id !== "9998" &&
          !data[x].id.includes("G12Finisher")
        ) {
          var d = { id: data[x].id, name: data[x].nameKey, tier: data[x].tier, iconKey: data[x].iconKey };
          equipment.push(d);
        }
      }
      equipment.sort((a, b) =>
        a.name.localeCompare(b.name, undefined, {
          numeric: true,
          sensitivity: "base",
        })
      );
      this.setState({ equipment: equipment, equipmentOut: equipment }, () => {
        this.applyFilters();
      });
    });

    axios.get("/json/stores/premiumCurrency.json").then((response) => {
      this.setState({ premiumCurrency: response.data });
    });
  }
  doSync() {
    base.syncState("user-gear/" + this.props.firebaseUser.uid, {
      context: this,
      state: "userGear",
      then: () => {
        this.setState({ loading: false });
      },
    });

    base.syncState("user-favorites/" + this.props.firebaseUser.uid, {
      context: this,
      state: "userFavorites",
      then: () => {
        this.applyFilters();
      },
    });
  }
  handleChange = (e) => {
    this.setState({
      userGear: {
        [e.target.id]: e.target.value,
      },
    });
  };
  filterName = (e) => {
    this.setState({ nameFilter: e.target.value.toLowerCase() }, () => {
      this.applyFilters();
    });
  };
  //Supposed to zero out missing gear.  Will have to come back to this later
  zeroMissingGear = () => {
    let equipment = cloneDeep(this.state.equipment);
    if (this.state.userGear.length === 0) {
      console.log("hello");
    } else {
      let equipmentArray = [];
      let userGearArray = [];
      let missingGearArray = [];

      let userGear = cloneDeep(this.state.userGear);

      for (let piece of equipment) {
        equipmentArray.push(piece.id);
      }

      for (let equipped in userGear) {
        userGearArray.push(equipped);
      }

      for (let x of equipmentArray) {
        if (!userGearArray.includes(x)) {
          missingGearArray.push(x);
        }
      }

      missingGearArray.sort();

      for (let x of missingGearArray) {
        userGear[x] = 0;
      }
      console.log(userGear["140"]);
      this.setState({ userGear });
      this.doSync();
    }
  };
  applyFilters = () => {
    let equipment = cloneDeep(this.state.equipment);

    //Add costs to the gear
    for (let piece of equipment) {
      let cost = this.state.premiumCurrency.filter(
        (item) => item.id === piece.id
      );
      if (cost[0]) {
        piece.cost = cost[0].cost;
      }
    }

    if (this.state.nameFilter !== "") {
      equipment = equipment.filter((equip) => {
        return equip.name.toLowerCase().includes(this.state.nameFilter);
      });
    }

    if (this.state.showFavorites) {
      equipment = equipment.filter((equip) => {
        return (
          this.state.userFavorites.hasOwnProperty(equip.id) &&
          this.state.userFavorites[equip.id]
        );
      });
    }

    this.setState({ equipmentOut: equipment });
  };
  showFavoritesToggle = (e) => {
    let value = e.target.checked;
    this.setState({ showFavorites: value }, () => {
      localStorage.setItem("showFavorites", this.state.showFavorites);
      this.applyFilters();
    });
  };
  favorite = (id) => {
    let value;
    if (this.state.userFavorites.hasOwnProperty(id)) {
      value = !this.state.userFavorites[id];
    } else {
      value = true;
    }

    this.setState({
      userFavorites: {
        [id]: value,
      },
    });
  };
  DisplayTable() {
    if (!this.state.loading) {
      return (
        <div className="container-gear">
          <TextField
            label="Filter by Name"
            fullWidth
            className="quantity search"
            onChange={this.filterName}
          />
          <br />

          <FormControlLabel
            control={
              <Switch
                checked={this.state.showFavorites}
                onChange={this.showFavoritesToggle}
                value="true"
                color="primary"
              />
            }
            label="Only Show Favorites"
          />
          <div className="gear-main-container">
            {this.state.equipmentOut.map((item) => {
              if (item.id != "G12Finisher_HK47_A") {
                let favoriteIcon =
                  this.state.userFavorites.hasOwnProperty(item.id) &&
                  this.state.userFavorites[item.id] ? (
                    <StarOutlined
                      className="star"
                      htmlColor="#ff0000"
                      onClick={() => {
                        this.favorite(item.id);
                      }}
                    />
                  ) : (
                    <StarBorderOutlined
                      className="star"
                      htmlColor="#ff0000"
                      onClick={() => {
                        this.favorite(item.id);
                      }}
                    />
                  );

                return (
                  <div className="gear-container" key={item.id}>
                    <div className="upper">
                      <div className={"gear gear-tier-" + item.tier}>
                        {favoriteIcon}
                        <img
                          src={`//game-assets.swgoh.gg/textures/${item.iconKey}.png`}
                          alt="{item.name}"
                          width="30"
                        />
                      </div>
                      <div className="item-name">{item.name}</div>
                      {item.cost ? (
                        <div className="item-cost">
                          {item.cost}
                          <img
                            src={"../img/Game-Icon-Crystal.png"}
                            style={{ height: "20px" }}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="gear-quantity">
                      <TextField
                        id={item.id}
                        placeholder={'Quantity'}
                        type="number"
                        fullWidth
                        className="quantity"
                        value={this.state.userGear[item.id] || ""}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                );
              }
            })}
          </div>
        </div>
      );
    } else if (!this.state.loggedIn) {
      return (
        <div className={'error'}>
            <FontAwesomeIcon icon={faExclamationTriangle} />
            Login with Google above to enter your gear
          </div>
      )
    } else {
      return (
        <div className={'error'}>
          <FontAwesomeIcon icon={faTruckLoading} />
          Loading
        </div>
      );
    }
  }
  render() {
    return <div className="container">{this.DisplayTable()}</div>;
  }
}

export default withStyles(styles)(UpdateGear);
