import React from "react";

import base from "../../rebase";
import firebase from "firebase/app";
import ReactTooltip from "react-tooltip";
import ReactTable from "react-table";
import "react-table/react-table.css";
import matchSorter from "match-sorter";

import Input from "@material-ui/core/Input";
import { Button } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Alert from "@material-ui/lab/Alert";
import { withStyles } from "@material-ui/core/styles";
import Presets from "./Presets";
import axios from "axios";
import { CSVLink } from "react-csv";
import SideBar from "../../components/Sidebar/SideBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSyncAlt } from "@fortawesome/pro-solid-svg-icons";

import "./GearTable.scss";
import "../../styles/modules/modal.scss";
import { ModalCharacter } from "./ModalCharacter";
import { faUserTimes } from "@fortawesome/pro-duotone-svg-icons";
import frameAtlasImg from "../../styles/img/g13-frame-atlas.png";

const cloneDeep = require("lodash.clonedeep");
const queryString = require("query-string");

let usedGear = [];
let usedRelics = [];
let userGearCopy = [];

const currencyArray = [
  { id: "premiumCurrency", picture: "Game-Icon-Crystal.png", name: "Crystals" },
  { id: "seasonsCurrency", picture: "Game-Icon-Championship_Token.png", name: "Championship Token" },
  { id: "guildCurrency", picture: "Game-Icon-Guild_Token.png", name: "Guild Token" },
  { id: "get1Currency", picture: "Game-Icon-Mk_I_Guild_Event_Token.png", name: "Guild Event Token I" },
  { id: "get2Currency", picture: "Game-Icon-Mk_II_Guild_Event_Token.png", name: "Guild Event Token II" },
  { id: "raid1Currency", picture: "tex.guild_raid_personal.png", name: "Mk1 Raid" },
  { id: "raid2Currency", picture: "tex.guild_raid_general.png", name: "Mk2 Raid" },
  { id: "shardCurrency", picture: "Game-Icon-Shard_Store_Token.png", name: "Shard Store Token" }
];

const relicCurrencyArray = [
  { id: "premiumCurrency", picture: "Game-Icon-Crystal.png", name: "Crystals" },
  { id: "raid2Currency", picture: "tex.guild_raid_general.png", name: "Mk2 Raid" },
  { id: "raid3Currency", picture: "tex.guild_raid_special.png", name: "Mk3 Raid" },
];

//Game currency icons that are currently unused.
const unusedCurrencyArray = [
  { id: "unused1", picture: "Game-Icon-Cantina_Battle_Tokens.png", name: "Cantina Battles Token" },
  { id: "unused2", picture: "Game-Icon-Credits.png", name: "Credits" },
  { id: "unused3", picture: "Game-Icon-Fleet_Arena_Token.png", name: "Fleet Arena Token" },
  { id: "unused4", picture: "Game-Icon-Galactic_War_Token.png", name: "Galactic War Token" },
  { id: "unused5", picture: "Game-Icon-Ship_Ability_Material_Prestige.png", name: "Ship Ability Material Prestige" },
  { id: "squadArenaCurrency", picture: "Game-Icon-Squad_Arena_Token.png", name: "Squad Arena Token" }
];

const useStyles = (theme) => ({
  root: {
    marginLeft: 250,
    marginRight: 10
  },
  heading: {
    fontWeight: theme.typography.fontWeightRegular
  }
});

class GearTable extends React.PureComponent {
  
  csvLink = React.createRef();

  constructor() {
    super();
    this.getGear = this.getGear.bind(this);
    this.getTooltip = this.getTooltip.bind(this);
    this.doSync = this.doSync.bind(this);
    this.calculateCharacterGear = this.calculateCharacterGear.bind(this);
    this.handleCharacterClick = this.handleCharacterClick.bind(this);
    this.getSwgoh = this.getSwgoh.bind(this);
    this.handleSwgohggData = this.handleSwgohggData.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleEnergyChange = this.handleEnergyChange.bind(this);
    this.download = this.download.bind(this);
    this.refresh = this.refresh.bind(this);
    this.handleModal = this.handleModal.bind(this);
    this.deleteCharacterModal = this.deleteCharacterModal.bind(this);
    this.removeAllCharacters = this.removeAllCharacters.bind(this);
    this.addCharacter = this.addCharacter.bind(this);
    this.getForceAlignment = this.getForceAlignment.bind(this);
    this.csvLink = React.createRef();
    this.state = {
      gear: [],
      selectedUnit: [],
      characterGear: [],
      userGear: { "001": 0 },
      relicLevel: [9, 8, 7, 6, 5, 4, 3, 2, 1, 0],
      gearLevel: [13, 12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1],
      using_hotUtils: false,
      allyCode: "",
      swgohData: {},
      isFetching: false,
      dataToDownload: [],
      hide_completed: false,
      hasFirebaseData: false,
      accountForCrafted: true,
      buyHDB: false,
      selectedLegendaries: [],
      relicQuantities: {},
      isModalOpen: false,
      addedCharacter: "",
      addedGearLevel: 13,
      addedRelicLevel: 0,
      isEditing: false,
      isFleetColumnVisible: false,
      isDSColumnVisible: false,
      isLSColumnVisible: false,
      isHighlightVisible: true,
      isTokensColumnVisible: true,
      isEnergyColumnVisible: true,
      isLowGearVisible: true,
      isHighGearVisible: true,
      isCompactMobileVisible: false,
      isIDColumnVisible: true,
      energy: {}
    };
  }

  getGear(equip, recipes, equipment, quantity, gear_level, targetGearLevel) {
    let that = this;
    if (!quantity) {
      quantity = 1;
    }
    if (typeof equip === "undefined") {
      return;
    }

    if (this.state.accountForCrafted) {
      if (userGearCopy[equip.id] >= 1) {
        if (
          equip &&
          (equip.hasOwnProperty("ingredients") || (equip.hasOwnProperty("recipeId") && equip.recipeId !== ""))
        ) {
          userGearCopy[equip.id] = userGearCopy[equip.id] - 1;
          quantity = quantity - 1;

          if (quantity && quantity > 0) {
            return that.getGear(equip, recipes, equipment, quantity, gear_level, targetGearLevel);
          } else {
            return;
          }
        }
      }
    }

    if (equip && equip.hasOwnProperty("ingredients")) {
      equip.ingredients.forEach((element) => {
        if (element.id !== "GRIND") {
          let newQuantity = quantity * element.minQuantity;
          return that.getGear(equipment[element.id], recipes, equipment, newQuantity, gear_level, targetGearLevel);
        }
        return;
      });
    } else if (equip.recipeId === "" || !equip.hasOwnProperty("recipeId")) {
      if (equip.id === "9999") {
        return;
      }

      if (
        gear_level < targetGearLevel &&
        ((this.state.isLowGearVisible && gear_level <= 6) ||
          (this.state.isHighGearVisible && gear_level > 6 && gear_level <= 11) ||
          gear_level > 11)
      ) {
        if (usedGear.find((x) => x.id === equip.id)) {
          var found = usedGear.find((x) => x.id === equip.id);
          found.quantity = found.quantity + quantity;
        } else {
          var gearCosts = {};
          currencyArray.forEach((piece) => {
            if (this.props[piece.id]) {
              let pieceCost = this.props[piece.id].filter((cost) => {
                return cost.id === equip.id;
              });
              pieceCost = pieceCost.length > 0 ? pieceCost[0].cost : "";
              gearCosts[piece.id] = pieceCost;
            }
          });
          usedGear.push({
            id: equip.id,
            name: equip.nameKey,
            quantity: quantity,
            mark: equip.mark,
            tier: equip.tier,
            lookupMissionList: equip.lookupMission,
            gearCosts,
            iconKey: equip.iconKey
          });
        }
        return equip;
      }
      return;
    } else {
      return that.getGear(recipes[equip.recipeId], recipes, equipment, quantity, gear_level, targetGearLevel);
    }
  }

  download = (event, done) => {
    var dataToDownload = [["Item", "Required", "Owned", "Type"]];
    this.state.gear.forEach((piece) => {
      dataToDownload.push([piece.name, piece.quantity, this.state.userGear[piece.id], 'gear']);
    });

    this.state.relicQuantities.forEach((piece) => {
      var relicName = this.props.relicNames.filter((p) => p.id === piece.name)[0].name
      dataToDownload.push([relicName, piece.quantity, this.state.userGear[piece.name], 'relic']);
    });

    this.setState({ dataToDownload }, () => {
      done();
    });
  };

  refresh = () => {
    this.getSwgoh(this.state.allyCode);
  };

  allyCodeChange = (e) => {
    this.setState({
      allyCode: e.target.value
    });

    if (e.target.value.length === 9) {
      this.getSwgoh(e.target.value);
      localStorage.setItem("allyCode", e.target.value);
    }
  };

  doSync() {
    if (!this.state.hasFirebaseData) {
      base.syncState("user-gear/" + this.props.firebaseUser.uid, {
        context: this,
        state: "userGear",
        defaultValue: 0,
        then: (e) => {
          this.setState({ loading: false, hasFirebaseData: true }, () => this.updateCharacter());
        }
      });
    }

    const parsed = queryString.parse(this.props.location.search);
    if (typeof parsed.HotAllyCode !== "undefined") {
      console.log("using hotutils");
      this.setState({ using_hotUtils: true, allyCode: parsed.HotAllyCode });
      let hotUtilUrl = "https://us-central1-swgoh-gear.cloudfunctions.net/hotUtils?HotAllyCode=" + parsed.HotAllyCode;
      if (process.env.NODE_ENV === "development") {
        hotUtilUrl = "https://us-central1-swgoh-gear.cloudfunctions.net/hotUtilsTest?HotAllyCode=" + parsed.HotAllyCode;
      }

      firebase
        .auth()
        .currentUser.getIdToken()
        .then((token) => {
          axios.get(hotUtilUrl, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
            },
            data: {}
          }); //Populate gear quantities from HotUtils into Firebase backend
        });

      localStorage.setItem("allyCode", parsed.HotAllyCode);
      // console.log("allycode set with hotUtils: ", this.HotAllyCode)
    }

    this.getSwgoh(this.state.allyCode);
  }

  //Fetch player profile and set it in state
  getSwgoh = (e) => {
    if (e.length !== 9) {
      return;
    }
    if (e == "undefined") {
      return;
    }

    if (Math.abs(new Date() - new Date(localStorage.getItem("swgohUpdateTime"))) > 60000) {
      let url = "https://us-central1-swgoh-gear.cloudfunctions.net/getUser?allyCode=";
      if (process.env.NODE_ENV === "development") {
        url = "https://us-central1-swgoh-gear.cloudfunctions.net/getUserTest?allyCode=";
      }
      axios.get(url + e).then((response) => {
        var swgohData = response.data;
        localStorage.setItem("swgohUpdateTime", new Date());
        localStorage.setItem("swgohData", JSON.stringify(swgohData));
        this.handleSwgohggData(swgohData);
      });
    } else {
      console.log("refreshed less than a minute ago");
    }
  };

  handleSwgohggData = (swgohData) => {
    this.setState({ swgohData }, () => {
      this.calculateCharacterGear();
    });
    this.setState({ isFetching: true });
  };

  handleChange = (e) => {
    this.setState({
      userGear: {
        [e.target.id]: e.target.value
      }
    });
  };

  handleEnergyChange = (e) => {
    this.setState({
      energy: {
        ...this.state.energy,
        [e.target.id]: e.target.value
      }
    });
  };

  updateCharacter = async () => {
    let charactersToGear = [];
    const promises = [];
    const unitsCache = await caches.open("units");

    this.state.selectedUnit.forEach((element) => {
      if (typeof unitsCache !== "undefined") {
        const promise = unitsCache
          .match("/" + element.id + ".json")
          .then((response) => response.json())
          .then((data) => {
            if (data.unitTier) {
              charactersToGear.push(data);
            } else {
              unitsCache.delete("/" + element.id + ".json");
            }
          })
          .catch(() => {
            return base.fetch("units/" + element.id + ":SEVEN_STAR", {}).then((data) => {
              charactersToGear.push(data);

              const jsonResponse = new Response(JSON.stringify(data), {
                headers: {
                  "content-type": "application/json"
                }
              });
              unitsCache.put("/" + element.id + ".json", jsonResponse);
            });
          });
        promises.push(promise);
      }
    });

    Promise.all(promises).then(() => {
      this.setState({ characterGear: charactersToGear }, () => {
        this.calculateCharacterGear();
      });
    });
  };

  calculateCharacterGear = () => {
    usedGear = [];
    usedRelics = [];
    let relicQuantities = {};

    let data = this.state.characterGear;
    let gear_needed = [];
    let currentRelicLevel = 0;
    let targetRelicLevel = 0;
    var currentCharacterGearLevel = "";
    if (this.state.swgohData) {
      var allCharacters = cloneDeep(this.state.swgohData);
    }
    data.forEach((element) => {
      currentCharacterGearLevel = 0;
      let targetGearLevel = this.state.selectedUnit.find((char) => char.id === element.baseId);

      if (targetGearLevel) {
        targetGearLevel = targetGearLevel.gear;
      } else {
        targetGearLevel = 0;
      }

      if (this.state.swgohData) {
        for (let x in allCharacters) {
          if (allCharacters[x].base_id === element.baseId) {
            currentCharacterGearLevel = allCharacters[x].currentTier;
            for (let z = 0; z < 6; z++) {
              if (!allCharacters[x].equipment.find((element) => element.slot === z)) {
                let temp = {
                  gear_level: currentCharacterGearLevel - 1,
                  targetGearLevel,
                  gear: element.unitTier[currentCharacterGearLevel - 1].equipmentSet[z]
                };
                gear_needed.push(temp);
              }
            }
          }
        }
      }
      for (let x in element.unitTier) {
        if (this.state.swgohData.length > 0) {
          if (x > currentCharacterGearLevel - 1) {
            for (let y in element.unitTier[x].equipmentSet) {
              let g = {
                gear_level: x,
                targetGearLevel,
                gear: element.unitTier[x].equipmentSet[y]
              };
              gear_needed.push(g);
            }
          }
        } else {
          for (let y in element.unitTier[x].equipmentSet) {
            let g = {
              gear_level: x,
              targetGearLevel,
              gear: element.unitTier[x].equipmentSet[y]
            };
            gear_needed.push(g);
          }
        }
      }
      if (this.state.swgohData.length > 0) {
        currentRelicLevel = this.state.swgohData.filter((char) => char.base_id === element.baseId);
        if (currentRelicLevel.length > 0) {
          currentRelicLevel = currentRelicLevel[0].relic;
        } else {
          currentRelicLevel = 0;
        }
      }
      if (this.state.selectedUnit) {
        targetRelicLevel = this.state.selectedUnit.filter((char) => char.id === element.baseId);
        if (targetRelicLevel.length > 0) {
          targetRelicLevel = targetRelicLevel[0].relics;
        } else {
          targetRelicLevel = 0;
        }
      }
      currentRelicLevel = currentRelicLevel - 2;
      if (currentRelicLevel < 0) {
        currentRelicLevel = 0;
      }

      let gearCosts = {};
      for (let relicLevel = currentRelicLevel; relicLevel < targetRelicLevel; relicLevel++) {
        usedRelics.push(this.props.relicRequirements[relicLevel]);
      }
      if (this.props.relicNames.length > 0) {
        for (let z of this.props.relicNames) {
          relicQuantities[z.id] = 0;
          for (let y of usedRelics) {
            if (y !== undefined && y[z.id] && y[z.id] !== undefined) {
              relicQuantities[z.id] = y[z.id] + relicQuantities[z.id] || 0;
            }
          }
        }
      }
    });
    const objectArray = Object.entries(relicQuantities);
    var relicArray = [];
    objectArray.forEach(([key, value]) => {
      relicArray.push({ name: [key][0], quantity: value });
    });
    this.setState({ relicQuantities: relicArray });
    this.updateDataTable(gear_needed); // All of the fully crafted gear pieces we need.
  };

  addCharacter = (e) => {
    this.setState({
      addedCharacter: e
    });
  };

  addGear = (e) => {
    this.setState({
      addedGearLevel: e.value
    });
  };

  addRelic = (e) => {
    this.setState({
      addedRelicLevel: e.value
    });
  };

  updateDataTable(equipment_needed) {
    userGearCopy = cloneDeep(this.state.userGear);
    equipment_needed.forEach((equipment) => {
      let equipment_id = equipment.gear;
      let gear_level = parseInt(equipment.gear_level) + 1;
      this.getGear(
        this.props.equipment[equipment_id],
        this.props.recipes,
        this.props.equipment,
        null,
        gear_level,
        equipment.targetGearLevel
      );
    });

    usedGear.sort((a, b) =>
      a.name.localeCompare(b.name, undefined, {
        numeric: true,
        sensitivity: "base"
      })
    );

    if (this.state.buyHDB) {
      if (this.props.hdb) {
        for (let hdbGear of usedGear) {
          let filteredPiece = this.props.hdb.filter((onePiece) => onePiece.id === hdbGear.id);
          if (filteredPiece.length) {
            filteredPiece = filteredPiece[0];
            hdbGear.quantity =
              hdbGear.quantity - filteredPiece.minQuantity > 0 ? hdbGear.quantity - filteredPiece.minQuantity : 0;
          }
        }
      }
    }
    this.setState({ gear: usedGear }, () => {
      ReactTooltip.rebuild();
      if (this.state.hasFirebaseData) {
        this.state.gear.forEach((element) => {
          if (typeof this.state.userGear[element.id] === "undefined") {
            this.setState({
              userGear: {
                [element.id]: 0
              }
            });
          }
        });
      }
    });
  }

  componentDidMount() {
    if (this.props.firebaseUser) {
      this.doSync();
    }
    if (localStorage.getItem("allyCode")) {
      this.setState({
        allyCode: localStorage.getItem("allyCode")
      });
      this.getSwgoh(localStorage.getItem("allyCode"));
      console.log(localStorage.getItem("allyCode"));
    }
    if (localStorage.getItem("accountForCrafted")) {
      this.setState({
        accountForCrafted: localStorage.getItem("accountForCrafted")
      });
    }
    if (localStorage.getItem("buyHDB")) {
      this.setState({
        buyHDB: localStorage.getItem("buyHDB")
      });
    }
    if (localStorage.getItem("swgohData")) {
      this.setState({
        swgohData: JSON.parse(localStorage.getItem("swgohData"))
      });
    }
    if (localStorage.getItem("selectedLegendaries")) {
      this.setState({
        selectedLegendaries: JSON.parse(localStorage.getItem("selectedLegendaries"))
      });
    }
    if (localStorage.getItem("selectedUnit")) {
      let selectedUnit = JSON.parse(localStorage.getItem("selectedUnit"));
      selectedUnit.forEach((element) => {
        if (!element.relics) {
          element.relics = 0;
        }
        if (!element.gear) {
          element.gear = 13;
        }
      });

      this.setState({ selectedUnit }, () => {
        this.updateCharacter();
      });
    }
    if (localStorage.getItem("isFleetColumnVisible")) {
      this.setState({
        isFleetColumnVisible: localStorage.getItem("isFleetColumnVisible") === "true"
      });
    }
    if (localStorage.getItem("isLSColumnVisible")) {
      this.setState({
        isLSColumnVisible: localStorage.getItem("isLSColumnVisible") === "true"
      });
    }
    if (localStorage.getItem("isDSColumnVisible")) {
      this.setState({
        isDSColumnVisible: localStorage.getItem("isDSColumnVisible") === "true"
      });
    }
    if (localStorage.getItem("isCompactMobileVisible")) {
      this.setState({
        isCompactMobileVisible: localStorage.getItem("isCompactMobileVisible") === "true"
      });
    }
    if (localStorage.getItem("isIDColumnVisible")) {
      this.setState({
        isIDColumnVisible: localStorage.getItem("isIDColumnVisible") === "true"
      });
    }
    if (localStorage.getItem("isTokensColumnVisible")) {
      this.setState({
        isTokensColumnVisible: localStorage.getItem("isTokensColumnVisible") === "true"
      });
    }
    if (localStorage.getItem("isHighlightVisible")) {
      this.setState({
        isHighlightVisible: localStorage.getItem("isHighlightVisible") === "true"
      });
    }
    if (localStorage.getItem("isEnergyColumnVisible")) {
      this.setState({
        isEnergyColumnVisible: localStorage.getItem("isEnergyColumnVisible") === "true"
      });
    }
    if (localStorage.getItem("isLowGearVisible")) {
      this.setState({
        isLowGearVisible: localStorage.getItem("isLowGearVisible") === "true"
      });
    }
    if (localStorage.getItem("isHighGearVisible")) {
      this.setState({
        isHighGearVisible: localStorage.getItem("isHighGearVisible") === "true"
      });
    }
    const parsed = queryString.parse(this.props.location.search);
    if (typeof parsed.HotAllyCode !== "undefined") {
      this.setState({ using_hotUtils: true, allyCode: parsed.HotAllyCode });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.allycode !== prevProps.allycode) {
      this.getSwgoh(this.props.allycode);
    }
    if (prevProps.firebaseUser !== this.props.firebaseUser) {
      if (this.props.firebaseUser && this.props.firebaseUser.uid) {
        this.doSync();
      }
    }

    if (prevProps.units !== this.props.units) {
      if (this.props.selectedCharacter) {
        this.setState(
          {
            selectedUnit: [
              this.props.units.find((e) => {
                if (e.id === this.props.selectedCharacter) {
                  return e;
                }
                return null;
              })
            ]
          },
          () => {
            this.updateCharacter();
          }
        );
      }
    }
  }

  getTooltip(d) {
    let mission = "";
    if (this.props.farmLocations[d.original.id]) {
      mission = mission + "Recommended Order:<br />" + this.props.farmLocations[d.original.id] + "<br /><br />";
    }

    let gear = d.original.lookupMissionList;
    mission = mission + "Nodes available:<br />";

    gear.sort((a, b) => {
      let campaign = a.missionIdentifier.campaignId.localeCompare(b.missionIdentifier.campaignId, undefined, {
        numeric: true,
        sensitivity: "base"
      });
      let map = a.missionIdentifier.campaignMapId.localeCompare(b.missionIdentifier.campaignMapId);
      let node = a.missionIdentifier.campaignMissionId.localeCompare(b.missionIdentifier.campaignMissionId);
      let difficulty = a.missionIdentifier.campaignNodeDifficulty === b.missionIdentifier.campaignNodeDifficult;
      return campaign || map || difficulty || node;
    });

    gear.forEach((element) => {
      let missionIdentifier = element.missionIdentifier;
      if (
        missionIdentifier.campaignId !== "EVENTS" &&
        missionIdentifier.campaignId !== "TW_EVENTS" &&
        missionIdentifier.campaignId !== "C00"
      ) {
        if (missionIdentifier.campaignId === "C01L") {
          mission = mission + "Light Side Battles ";
        } else if (missionIdentifier.campaignId === "C01D") {
          mission = mission + "Dark Side Battles ";
        } else if (missionIdentifier.campaignId === "C01SP") {
          mission = mission + "Fleet Battles ";
        }

        let map = missionIdentifier.campaignMapId.slice(-2);
        map = parseInt(map);
        mission = mission + map + "-";

        let node = missionIdentifier.campaignMissionId.slice(-2);
        node = parseInt(node);

        let letter =
          this.props.missions[missionIdentifier.campaignId][missionIdentifier.campaignMapId][
            missionIdentifier.campaignNodeDifficulty
          ][missionIdentifier.campaignMissionId];
        mission = mission + letter;
        if (missionIdentifier.campaignNodeDifficulty === 4) {
          mission = mission + " Normal";
        } else if (missionIdentifier.campaignNodeDifficulty === 5) {
          mission = mission + " Hard";
        }

        mission = mission + "<br>";
      }
    });
    return mission;
  }

  handleHideCompletedGear(value) {
    this.setState({ hide_completed: value }, () => {
      this.calculateCharacterGear();
    });
  }

  toggleFleetColumnBattles() {
    this.setState(
      {
        isFleetColumnVisible: !this.state.isFleetColumnVisible
      },
      () => {
        localStorage.setItem("isFleetColumnVisible", this.state.isFleetColumnVisible);
      }
    );
  }

  toggleLSColumnBattles() {
    this.setState(
      {
        isLSColumnVisible: !this.state.isLSColumnVisible
      },
      () => {
        localStorage.setItem("isLSColumnVisible", this.state.isLSColumnVisible);
      }
    );
  }

  toggleDSColumnBattles() {
    this.setState(
      {
        isDSColumnVisible: !this.state.isDSColumnVisible
      },
      () => {
        localStorage.setItem("isDSColumnVisible", this.state.isDSColumnVisible);
      }
    );
  }

  toggleCompactMobile() {
    this.setState(
      {
        isCompactMobileVisible: !this.state.isCompactMobileVisible
      },
      () => {
        localStorage.setItem("isCompactMobileVisible", this.state.isCompactMobileVisible);
      }
    );
  }

  toggleIDColumnVisible() {
    this.setState(
      {
        isIDColumnVisible: !this.state.isIDColumnVisible
      },
      () => {
        localStorage.setItem("isIDColumnVisible", this.state.isIDColumnVisible);
      }
    );
  }

  toggleTokensColumnVisible() {
    this.setState(
      {
        isTokensColumnVisible: !this.state.isTokensColumnVisible
      },
      () => {
        localStorage.setItem("isTokensColumnVisible", this.state.isTokensColumnVisible);
      }
    );
  }

  toggleEnergyColumnVisible() {
    this.setState(
      {
        isEnergyColumnVisible: !this.state.isEnergyColumnVisible
      },
      () => {
        localStorage.setItem("isEnergyColumnVisible", this.state.isEnergyColumnVisible);
      }
    );
  }

  toggleHighGearVisible() {
    this.setState(
      {
        isHighGearVisible: !this.state.isHighGearVisible
      },
      () => {
        localStorage.setItem("isHighGearVisible", this.state.isHighGearVisible);
        this.calculateCharacterGear();
      }
    );
  }

  togglelowGearVisible() {
    this.setState(
      {
        isLowGearVisible: !this.state.isLowGearVisible
      },
      () => {
        localStorage.setItem("isLowGearVisible", this.state.isLowGearVisible);
        this.calculateCharacterGear();
      }
    );
  }

  toggleHighlightVisible() {
    this.setState(
      {
        isHighlightVisible: !this.state.isHighlightVisible
      },
      () => {
        localStorage.setItem("isHighlightVisible", this.state.isHighlightVisible);
      }
    );
  }

  handleCraftedGear(value) {
    this.setState({ accountForCrafted: value }, () => {
      this.calculateCharacterGear();
    });
  }

  handleBuyHDB(value) {
    this.setState({ buyHDB: value }, () => {
      this.calculateCharacterGear();
    });
  }

  handleShowLegendary = (legendary, isSelected) => {
    let selectedLegendaries;

    if (isSelected) {
      selectedLegendaries = [...this.state.selectedLegendaries, legendary];
    } else {
      selectedLegendaries = this.state.selectedLegendaries.filter((x) => x.value !== legendary.value);
    }

    this.setState({ selectedLegendaries }, () => {
      localStorage.setItem("selectedLegendaries", JSON.stringify(selectedLegendaries));
      if (!isSelected) {
        this.handleRemoveLegendary(legendary);
      } else {
        this.handleUpdateLegendaries();
      }
    });
  };

  handleRemoveLegendary(legendary) {
    const selectedUnits = [...this.state.selectedUnit];
    let newUnits = [];
    if (legendary.value !== 'ALL' && legendary.value !== 'ALLG12') {
      newUnits = selectedUnits.filter(({ id: id1 }) => !legendary["characters"].some(({ id: id2 }) => id2 === id1));
    }

    this.setState({ selectedUnit: newUnits }, () => {
      localStorage.setItem("selectedUnit", JSON.stringify(newUnits));
      this.updateCharacter();
      this.handleUpdateLegendaries();
    });
  }

  handleUpdateLegendaries = () => {
    let selectedUnits = [...this.state.selectedUnit];
    if (this.state.selectedLegendaries.length > 0) {
      for (let legendarySelected of this.state.selectedLegendaries) {
        if (legendarySelected.value === 'ALL' || legendarySelected.value === 'ALLG12') {
          const gear = legendarySelected.value === 'ALL' ? 13 : 12
          this.props.units.forEach(char => {
            if (selectedUnits.filter(e => e.id === char.id).length === 0) {
              selectedUnits.push({
                id: char.id,
                name: char.name,
                relics: 0,
                gear: gear,
                thumbnailName: char.thumbnailName
              });
            }
          });
        } else {
          legendarySelected.characters.forEach((character) => {
            for (let unit of selectedUnits) {
              if (character.id === unit.id) {
                if (character.gear === unit.gear) {
                  if (character.relic === unit.relics) {
                    return;
                  }
                  for (let updateRelic of selectedUnits) {
                    if (updateRelic.id === unit.id) {
                      updateRelic.relics = Math.max(unit.relics, character.relic);
                    }
                  }
                  return;
                }
                return;
              }
            }
  
            let char = this.props.units.find((x) => x.id === character.id);
  
            selectedUnits.push({
              id: character.id,
              name: char.name,
              relics: character.relic,
              gear: character.gear,
              thumbnailName: char.thumbnailName
            });
          });
        }
      }
    }

    this.setState({ selectedUnit: selectedUnits }, () => {
      localStorage.setItem("selectedUnit", JSON.stringify(selectedUnits));
      this.updateCharacter();
    });
  };

  handleCharacterClick = (unit, e) => {
    const unitInfo = this.props.units.find((x) => x.id === unit.id);

    this.setState({
      isModalOpen: true,
      addedCharacter: unitInfo,
      addedRelicLevel: unit.relics,
      addedGearLevel: unit.gear,
      isEditing: false
    });
  };

  handleModal = () => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
      addedCharacter: "",
      addedRelicLevel: 0,
      addedGearLevel: 13,
      isEditing: true
    });
  };

  deleteCharacterModal = () => {
    let selectedUnit = this.state.selectedUnit.filter((x) => x.id !== this.state.addedCharacter.id);

    this.setState({ selectedUnit: selectedUnit }, () => {
      this.updateCharacter();
    });
    localStorage.setItem("selectedUnit", JSON.stringify(selectedUnit));

    this.setState({
      isModalOpen: !this.state.isModalOpen,
      selectedUnit,
      addedCharacter: "",
      addedRelicLevel: 0,
      addedGearLevel: 13
    });
  };

  saveModal = () => {
    let addedRelicLevel = this.state.addedRelicLevel;
    if (this.state.addedGearLevel < 13) {
      addedRelicLevel = 0;
    }

    let selectedUnit = this.state.selectedUnit.filter((x) => x.id !== this.state.addedCharacter.id);
    selectedUnit.push({
      id: this.state.addedCharacter.id,
      name: this.state.addedCharacter.name,
      relics: addedRelicLevel,
      gear: this.state.addedGearLevel,
      thumbnailName: this.state.addedCharacter.thumbnailName
    });

    this.setState(
      {
        isModalOpen: !this.state.isModalOpen,
        selectedUnit,
        addedCharacter: "",
        addedRelicLevel: 0,
        addedGearLevel: 13
      },
      () => {
        localStorage.setItem("selectedUnit", JSON.stringify(selectedUnit));
        this.updateCharacter();
      }
    );
  };

  removeAllCharacters() {
    this.setState(
      {
        selectedLegendaries: [],
        selectedUnit: []
      },
      () => {
        this.updateCharacter();
      }
    );
    localStorage.setItem("selectedLegendaries", JSON.stringify([]));
    localStorage.setItem("selectedUnit", JSON.stringify([]));
  }

  getForceAlignment = (unit) => {
    const unitInfo = this.props.units.find((x) => x.id === unit.id);

    if (unitInfo && unitInfo.forceAlignment === 2) {
      return "light";
    } else if (unitInfo && unitInfo.forceAlignment === 3) {
      return "dark";
    } else {
      return "grey";
    }
  };

  batchUpdateSpendingNormalEnergy(event) {
    const value = parseInt(event.target.value);

    if (!this.state.gear || this.state.gear.length === 0 || isNaN(value)) {
      return;
    }

    if (!this.state.gear || this.state.gear.length === 0) {
      return;
    }

    const energy = { ...this.state.energy };

    for (let i = 0; i < this.state.gear.length; i++) {
      const gear = this.state.gear[i];

      if (
        gear.lookupMissionList.filter((x) => x.missionIdentifier.campaignId === "C01D").length === 0 &&
        gear.lookupMissionList.filter((x) => x.missionIdentifier.campaignId === "C01L").length === 0
      ) {
        continue;
      }

      energy[`dsls-energy-${gear.id}`] = value;
    }

    this.setState({ energy: energy });
  }

  batchUpdateSpendingFleetEnergy(event) {
    const value = parseInt(event.target.value);

    if (!this.state.gear || this.state.gear.length === 0 || isNaN(value)) {
      return;
    }

    const energy = { ...this.state.energy };

    for (let i = 0; i < this.state.gear.length; i++) {
      const gear = this.state.gear[i];

      if (gear.lookupMissionList.filter((x) => x.missionIdentifier.campaignId === "C01SP").length === 0) {
        continue;
      }

      energy[`fleet-energy-${gear.id}`] = value;
    }

    this.setState({ energy: energy });
  }

  getModalUnits() {
    const selectedUnitIds = this.state.selectedUnit.map((x) => x.id);

    return this.props.units.filter(
      (x) => !selectedUnitIds.includes(x.id) || (this.state.addedCharacter && this.state.addedCharacter.id === x.id)
    );
  }

  render() {
    const relicColumns = [
      {
        Header: "Name",
        accessor: "name",
        minWidth: 250,
        className: "name",
        Cell: (row) => (
          <>
            {this.props.relicNames
              .filter((piece) => piece.id === row.original.name)
              .map((filtered) => (
                <div className={"relic"} key={row.original.name}>
                  <img
                    src={"//game-assets.swgoh.gg/textures/" + filtered.iconKey + ".png"}
                    title={filtered.name}
                    alt={row.original.name}
                    className={"relic-img"}
                  />
                  <div className={"relic-txt"} title={filtered.name}>
                    {filtered.name}
                  </div>
                </div>
              ))}
          </>
        )
      },
      {
        Header: "Required",
        accessor: "quantity",
        filterable: false,
        className: this.state.isCompactMobileVisible ? 'flex-center required hideMobile' : 'flex-center required',
        Cell: (row) => (
          <div className={"row-txt"} title={row.original.quantity}>
            {row.original.quantity}
          </div>
        )
      },
      {
        id: "toFarm",
        Header: "Needed",
        accessor: (row) => `${row.name} ${row.quantity}`,
        filterable: false,
        className: "flex-center needed",
        sortMethod: (a, b) => {
          return (
            parseInt(b.split(" ")[1]) -
            (this.state.userGear[b.split(" ")[0]] || 0) -
            (parseInt(a.split(" ")[1]) - (this.state.userGear[a.split(" ")[0]] || 0))
          );
        },
        Cell: (row) =>
          this.state.userGear[row.original.name] ? (
            <div
              className={"row-txt"}
              title={
                row.original.quantity - this.state.userGear[row.original.name] > 0
                  ? row.original.quantity - this.state.userGear[row.original.name]
                  : 0
              }
            >
              {row.original.quantity - this.state.userGear[row.original.name] > 0
                ? row.original.quantity - this.state.userGear[row.original.name]
                : 0}
            </div>
          ) : (
            <div className={"row-txt"} title={row.original.quantity}>
              {row.original.quantity}
            </div>
          )
      },
      {
        Header: "Owned",
        accessor: "owned",
        filterable: false,
        className: this.state.isCompactMobileVisible ? 'flex-center owned hideMobile' : 'flex-center owned',
        width: 100,
        sortMethod: (a, b) => {
          return this.state.userGear[a] - this.state.userGear[b];
        },
        Cell: (row) =>
          this.props.firebaseUser &&
          this.props.firebaseUser.uid && (
            <Input
              id={row.original.name}
              label="Qty"
              type="number"
              className="quantity"
              value={this.state.userGear[row.original.name] === undefined ? "" : this.state.userGear[row.original.name]}
              onChange={this.handleChange}
              inputProps={{
                "aria-label": "Qty"
              }}
              style={{ width: 90 }}
            />
          )
      },
      {
        id: "daysNeeded",
        Header: "Days Remaining",
        filterable: false,
        show: !!this.state.isEnergyColumnVisible,
        className: this.state.isCompactMobileVisible ? 'flex-center days-needed hideMobile' : 'flex-center days-needed',
        Cell: (row) => {
          if (!row.original.name.startsWith("RM_")) {
            return null;
          }

          const userGearName = parseInt(this.state.userGear[row.original.name]);
          const neededRelic = row.original.quantity - userGearName > 0 ? row.original.quantity - userGearName : 0;
          let days = 0;

          if (row.original.name === "RM_001") {
            days = Math.floor(neededRelic / (((this.state.energy[`energy-${row.original.name}`] || 525) / 16) * 1.38));
          } else if (row.original.name === "RM_002") {
            days = Math.floor(neededRelic / (((this.state.energy[`energy-${row.original.name}`] || 525) / 16) * 0.94));
          } else if (row.original.name === "RM_003") {
            days = Math.floor(neededRelic / (((this.state.energy[`energy-${row.original.name}`] || 525) / 16) * 0.66));
          }

          return (
            <div className={"row-txt"} title={days}>
              {days}
            </div>
          );
        }
      },
      {
        Header: "Spending Energy/Day",
        id: "spendingCantinaEnergy",
        filterable: false,
        className: this.state.isCompactMobileVisible ? 'flex-center energy hideMobile' : 'flex-center energy',
        show: !!this.state.isEnergyColumnVisible,
        width: 100,
        Cell: (row) => {
          if (!row.original.name.startsWith("RM_")) {
            return null;
          }
          const handleFocus = (event) => event.target.select();

          return (
            <Input
              id={`energy-${row.original.name}`}
              label="Energy"
              type="number"
              className="quantity"
              onFocus={handleFocus}
              value={this.state.energy[`energy-${row.original.name}`] || 525}
              onChange={this.handleEnergyChange}
              inputProps={{
                "aria-label": "Qty"
              }}
              style={{ width: 90 }}
            />
          );
        }
      },
      {
        Header: () => {
          let currencySum = 0;
          this.props.relicNames.forEach((piece) => {
            if (
              piece.premiumCurrency > 0 &&
              this.state.relicQuantities.length > 0 &&
              Object.keys(this.state.userGear).length > 0
            ) {
              for (let amount of this.state.relicQuantities) {
                if (amount.name === piece.id) {
                  if (amount.quantity - this.state.userGear[piece.id] > 0) {
                    currencySum += (amount.quantity - this.state.userGear[piece.id]) * piece.premiumCurrency;
                  }
                }
              }
            }
          });
          return (
            <div className={"token"}>
              {currencySum > 0 && !isNaN(currencySum) ? currencySum : 0}
              <img src={"../img/Game-Icon-Crystal.png"} style={{ height: "20px" }} alt={currencySum} />
            </div>
          );
        },
        accessor: "currency",
        className: this.state.isCompactMobileVisible ? 'flex-center crystals hideMobile' : 'flex-center crystals',
        minWidth: 150,
        filterable: false,
        show: !!this.state.isTokensColumnVisible,
        Cell: (row) => {
          const neededGear = row.original.quantity - this.state.userGear[row.original.name];
          const requiredGear = this.props.relicNames.filter((piece) => piece.id === row.original.name)[0]
            .premiumCurrency;

          return this.state.userGear[row.original.name] && this.props.relicNames && neededGear > 0 ? (
            <div className={"token"} title={neededGear > 0 ? (requiredGear > 0 ? requiredGear * neededGear : "") : "0"}>
              {neededGear > 0 ? (requiredGear > 0 ? requiredGear * neededGear : "") : "0"}

              {neededGear ? (
                <img src={"../img/Game-Icon-Crystal.png"} style={{ height: "20px" }} alt={row.original.id} />
              ) : (
                "-"
              )}
            </div>
          ) : (
            ""
          );
        }
      }
    ];
    const columns = [
      {
        Header: "Name",
        accessor: "name",
        filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["name"] }),
        filterAll: true,
        className: "name",
        minWidth: 200,
        Cell: (row) => (
          <div className={"gear"} data-tip={this.getTooltip(row)}>
            <div
              className={"gear-border gear-tier-" + row.original.tier + " gear-id: " + row.original.id}
              title={row.row.name}
            >
              <img
                src={"https://game-assets.swgoh.gg/textures/" + row.original.iconKey + ".png"}
                alt={row.original.name}
                className={"gear-img"}
              />
            </div>
            <div className={"gear-txt"} title={row.row.name}>
              {row.row.name}
            </div>
          </div>
        )
      },
      {
        Header: "ID",
        accessor: "id",
        filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["id"] }),
        filterAll: true,
        className: "name",
        show: !this.state.isIDColumnVisible,
        minWidth: 200,
        Cell: (row) => (
          <div className={"gear"} data-tip={this.getTooltip(row)}>
            <div className={"gear-txt"} title={row.row.name}>
              {row.row.id}
            </div>
          </div>
        )
      },
      {
        Header: "Required",
        accessor: "quantity",
        filterable: false,
        width: 90,
        className: this.state.isCompactMobileVisible ? 'flex-center required hideMobile' : 'flex-center required',
        Cell: (row) => (
          <div className={"row-txt"} title={row.value} data-tip={this.getTooltip(row)}>
            {row.value}
            {this.props.hdb && this.state.buyHDB ? (
              this.props.hdb
                .filter((hdbpiece) => hdbpiece.id === row.row.id)
                .map((filtered) => (
                  <div key={filtered.id} className={"hdb"}>
                    {" "}
                    ({filtered.minQuantity} from HDB)
                  </div>
                ))
            ) : (
              <div />
            )}
          </div>
        )
      },
      {
        id: "toFarm",
        Header: "Needed",
        accessor: (row) => `${row.id} ${row.quantity}`,
        filterable: false,
        width: 90,
        className: "flex-center needed",
        sortMethod: (a, b) => {
          return (
            parseInt(b.split(" ")[1]) -
            (this.state.userGear[b.split(" ")[0]] || 0) -
            (parseInt(a.split(" ")[1]) - (this.state.userGear[a.split(" ")[0]] || 0))
          );
        },
        Cell: (row) =>
          this.state.userGear[row.row.id] ? (
            <div
              className={"row-txt"}
              title={
                row.row.quantity - this.state.userGear[row.row.id] > 0
                  ? row.row.quantity - this.state.userGear[row.row.id]
                  : 0
              }
              data-tip={this.getTooltip(row)}
            >
              {row.row.quantity - this.state.userGear[row.row.id] > 0
                ? row.row.quantity - this.state.userGear[row.row.id]
                : 0}
            </div>
          ) : (
            <div className={"row-txt"} title={row.row.quantity}>
              {row.row.quantity}
            </div>
          )
      },
      {
        Header: "Owned",
        accessor: "id",
        width: 110,
        filterable: false,
        className: this.state.isCompactMobileVisible ? 'flex-center owned hideMobile' : 'flex-center owned',
        sortMethod: (a, b) => {
          return this.state.userGear[a] - this.state.userGear[b];
        },
        Cell: (row) =>
          this.props.firebaseUser &&
          this.props.firebaseUser.uid && (
            <Input
              id={row.original.id}
              label="Qty"
              type="number"
              className="quantity"
              value={this.state.userGear[row.original.id] === undefined ? "" : this.state.userGear[row.original.id]}
              onChange={this.handleChange}
              inputProps={{
                "aria-label": "Qty"
              }}
              style={{ width: 90 }}
            />
          )
      },
      {
        Header: "Light Battles",
        accessor: "farmLS",
        show: !this.state.isLSColumnVisible,
        filterable: false,
        className: this.state.isCompactMobileVisible ? 'farm-light-side hideMobile' : 'farm-light-side',
        minWidth: 140,
        Cell: (row) => (
          <div className={"locations"}>
            {row.original.lookupMissionList
              .filter((x) => x.missionIdentifier.campaignId === "C01L")
              .map((x) => {
                const map = x.missionIdentifier.campaignMapId.slice(-2);
                const letter =
                  this.props.missions[x.missionIdentifier.campaignId][x.missionIdentifier.campaignMapId][
                    x.missionIdentifier.campaignNodeDifficulty
                  ][x.missionIdentifier.campaignMissionId];
                let difficulty = "";

                if (x.missionIdentifier.campaignNodeDifficulty === 4) {
                  difficulty = " Normal";
                } else if (x.missionIdentifier.campaignNodeDifficulty === 5) {
                  difficulty = " Hard";
                }

                return (
                  <div
                    key={parseInt(map) + "-" + letter + difficulty}
                    className={"item"}
                    title={parseInt(map) + "-" + letter + difficulty}
                    data-tip={this.getTooltip(row)}
                  >
                    <span>{parseInt(map) + "-" + letter}</span> {difficulty}
                  </div>
                );
              })}
          </div>
        )
      },
      {
        Header: "Dark Battles",
        accessor: "farmDS",
        show: !this.state.isDSColumnVisible,
        filterable: false,
        className: this.state.isCompactMobileVisible ? 'farm-dark-side hideMobile' : 'farm-dark-side',
        minWidth: 140,
        Cell: (row) => (
          <div className={"locations"}>
            {row.original.lookupMissionList
              .filter((x) => x.missionIdentifier.campaignId === "C01D")
              .map((x) => {
                const map = x.missionIdentifier.campaignMapId.slice(-2);
                const letter =
                  this.props.missions[x.missionIdentifier.campaignId][x.missionIdentifier.campaignMapId][
                    x.missionIdentifier.campaignNodeDifficulty
                  ][x.missionIdentifier.campaignMissionId];
                let difficulty = "";

                if (x.missionIdentifier.campaignNodeDifficulty === 4) {
                  difficulty = " Normal";
                } else if (x.missionIdentifier.campaignNodeDifficulty === 5) {
                  difficulty = " Hard";
                }

                return (
                  <div
                    key={parseInt(map) + "-" + letter + difficulty}
                    className={"item"}
                    title={parseInt(map) + "-" + letter + difficulty}
                    data-tip={this.getTooltip(row)}
                  >
                    <span>{parseInt(map) + "-" + letter}</span> {difficulty}
                  </div>
                );
              })}
          </div>
        )
      },
      {
        Header: "Fleet Battles",
        accessor: "farmFB",
        show: !this.state.isFleetColumnVisible,
        filterable: false,
        className: this.state.isCompactMobileVisible ? 'farm-fleet hideMobile' : 'farm-fleet',
        minWidth: 100,
        Cell: (row) => (
          <div className={"locations"}>
            {row.original.lookupMissionList
              .filter((x) => x.missionIdentifier.campaignId === "C01SP")
              .map((x) => {
                const map = x.missionIdentifier.campaignMapId.slice(-2);
                const letter =
                  this.props.missions[x.missionIdentifier.campaignId][x.missionIdentifier.campaignMapId][
                    x.missionIdentifier.campaignNodeDifficulty
                  ][x.missionIdentifier.campaignMissionId];
                let difficulty = "";

                if (x.missionIdentifier.campaignNodeDifficulty === 4) {
                  difficulty = " Normal";
                } else if (x.missionIdentifier.campaignNodeDifficulty === 5) {
                  difficulty = " Hard";
                }

                return (
                  <div
                    key={parseInt(map) + "-" + letter + difficulty}
                    className={"item"}
                    title={parseInt(map) + "-" + letter + difficulty}
                    data-tip={this.getTooltip(row)}
                  >
                    <span>{parseInt(map) + "-" + letter}</span> {difficulty}
                  </div>
                );
              })}
          </div>
        )
      },
      {
        id: "energyDaysNeeded",
        Header: "Days Remaining",
        show: !!this.state.isEnergyColumnVisible,
        width: 80,
        filterable: false,
        className: this.state.isCompactMobileVisible ? 'flex-center days-needed hideMobile' : 'flex-center days-needed',
        Cell: (row) => {
          if (
            row.original.lookupMissionList.filter((x) => x.missionIdentifier.campaignId === "C01D").length === 0 &&
            row.original.lookupMissionList.filter((x) => x.missionIdentifier.campaignId === "C01L").length === 0 &&
            row.original.lookupMissionList.filter((x) => x.missionIdentifier.campaignId === "C01SP").length === 0
          ) {
            return null;
          }

          const neededGear = row.original.quantity - parseInt(this.state.userGear[row.original.id]);

          const fleetEnergy = ((this.state.energy[`fleet-energy-${row.original.id}`] || 645) / 10) * 0.22;
          const normalEnergy = ((this.state.energy[`dsls-energy-${row.original.id}`] || 735) / 10) * 0.22;
          let days = 0;

          if (neededGear > 0) {
            if (row.original.lookupMissionList.filter((x) => x.missionIdentifier.campaignId === "C01SP").length === 0) {
              days = Math.floor(neededGear / normalEnergy);
            } else if (
              row.original.lookupMissionList.filter((x) => x.missionIdentifier.campaignId === "C01D").length === 0 &&
              row.original.lookupMissionList.filter((x) => x.missionIdentifier.campaignId === "C01L").length === 0
            ) {
              days = Math.floor(neededGear / fleetEnergy);
            } else {
              days = Math.floor(neededGear / (normalEnergy + fleetEnergy));
            }
          }

          return (
            <div className={"row-txt"} data-tip={this.getTooltip(row)} title={days}>
              {days}
            </div>
          );
        }
      },
      {
        Header: "Spending Energy/Day",
        id: "spendingNormalEnergy",
        filterable: false,
        className: this.state.isCompactMobileVisible ? 'flex-center energy hideMobile' : 'flex-center energy',
        show: !!this.state.isEnergyColumnVisible,
        width: 100,
        Cell: (row) => {
          if (
            row.original.lookupMissionList.filter((x) => x.missionIdentifier.campaignId === "C01D").length === 0 &&
            row.original.lookupMissionList.filter((x) => x.missionIdentifier.campaignId === "C01L").length === 0
          ) {
            return null;
          }
          const handleFocus = (event) => event.target.select();

          return (
            this.props.firebaseUser &&
            this.props.firebaseUser.uid && (
              <Input
                id={`dsls-energy-${row.original.id}`}
                label="DS/LS Energy"
                type="number"
                className="quantity"
                onFocus={handleFocus}
                value={this.state.energy[`dsls-energy-${row.original.id}`] || 735}
                onChange={this.handleEnergyChange}
                inputProps={{
                  "aria-label": "Qty"
                }}
                style={{ width: 90 }}
              />
            )
          );
        }
      },
      {
        Header: "Spending Fleet Energy/Day",
        id: "spendingFleetEnergy",
        filterable: false,
        className: this.state.isCompactMobileVisible ? 'flex-center fleet-energy hideMobile' : 'flex-center fleet-energy',
        show: !!this.state.isEnergyColumnVisible,
        width: 100,
        Cell: (row) => {
          if (row.original.lookupMissionList.filter((x) => x.missionIdentifier.campaignId === "C01SP").length === 0) {
            return null;
          }
          const handleFocus = (event) => event.target.select();

          return (
            this.props.firebaseUser &&
            this.props.firebaseUser.uid && (
              <Input
                id={`fleet-energy-${row.original.id}`}
                label="Fleet Energy"
                type="number"
                className="quantity"
                onFocus={handleFocus}
                value={this.state.energy[`fleet-energy-${row.original.id}`] || 645}
                onChange={this.handleEnergyChange}
                inputProps={{
                  "aria-label": "Qty"
                }}
                style={{ width: 90 }}
              />
            )
          );
        }
      },
      ...currencyArray.map((currency) => {
        return {
          Header: () => {
            let currencySum = 0;

            for (let piece of this.state.gear) {
              if (piece.gearCosts[currency.id] && piece.quantity - this.state.userGear[piece.id] > 0) {
                currencySum += piece.gearCosts[currency.id] * (piece.quantity - this.state.userGear[piece.id]);
              }
            }

            return (
              <div key={currency.id} className={"token"} title={currency.name}>
                {parseInt(currencySum)}
                <img
                  src={"../img/" + currency.picture}
                  style={{ height: "20px" }}
                  alt={currency.name}
                  title={currency.name}
                />
              </div>
            );
          },
          accessor: currency.id,
          filterable: false,
          show: !!this.state.isTokensColumnVisible,
          width: 75,
          className: this.state.isCompactMobileVisible ? 'flex-center tokens hideMobile' : 'flex-center tokens',
          sortMethod: (a, b) => {
            return this.state.userGear[a] - this.state.userGear[b];
          },
          Cell: (row) => {
            if (
              row.original.gearCosts &&
              row.original.gearCosts[currency.id] &&
              row.original.gearCosts[currency.id] * (row.original.quantity - this.state.userGear[row.original.id]) > 0
            ) {
              return (
                <div
                  key={currency.id}
                  className={"token"}
                  title={parseFloat(
                    row.original.gearCosts[currency.id] * (row.original.quantity - this.state.userGear[row.original.id])
                  ).toFixed(0)}
                >
                  {parseFloat(
                    row.original.gearCosts[currency.id] * (row.original.quantity - this.state.userGear[row.original.id])
                  ).toFixed(0)}
                  <img src={"../img/" + currency.picture} className={"token-img"} alt={currency.id} />
                </div>
              );
            } else {
              return null;
            }
          }
        };
      })
    ];
    const highlightRow = (state, rowInfo) => {
      if (this.props.firebaseUser) {
        return {
          style: {
            display:
              (rowInfo.row.quantity <= (this.state.userGear[rowInfo.row.name] || this.state.userGear[rowInfo.row.id]) ||
                rowInfo.row.quantity === 0) &&
              this.state.hide_completed
                ? "none"
                : "flex",
            background: this.state.isHighlightVisible
              ? rowInfo.row.quantity <=
                  (this.state.userGear[rowInfo.row.name] || this.state.userGear[rowInfo.row.id]) ||
                rowInfo.row.quantity === 0
                ? "#e2fdf9"
                : "#fff0f1"
              : ""
          }
        };
      } else {
        return {};
      }
    };

    let DisplayTable =
      this.state.gear.length > 0 ? (
        <ReactTable
          data={this.state.gear}
          className={"table-body"}
          columns={columns}
          filterable
          minRows={0}
          defaultPageSize={200}
          showPagination={false}
          showPageSizeOptions={false}
          getTrProps={highlightRow}
          // defaultSorted={[{id: "toFarm", desc: false}]}
        />
      ) : (
        ""
      );

    let RelicTable =
      this.state.relicQuantities.length > 0 ? (
        <ReactTable
          data={this.state.relicQuantities}
          columns={relicColumns}
          className={"table-body"}
          filterable
          minRows={0}
          defaultPageSize={200}
          showPagination={false}
          showPageSizeOptions={false}
          getTrProps={highlightRow}
          // defaultSorted={[{id: "toFarm", desc: false}]}
        />
      ) : (
        ""
      );

    let HotUtilsError =
      this.state.using_hotUtils && !this.props.firebaseUser ? (
        <Alert severity="error">
          You must Login with Google before syncing data with HotUtils. Please login from the menu above then go back to
          HotUtils and click the SE: Gear button.
        </Alert>
      ) : (
        ""
      );

    let DisplayLastUpdated = localStorage.getItem("swgohUpdateTime")
      ? new Date(localStorage.getItem("swgohUpdateTime")).toLocaleString("en-US")
      : "";

    const stars = [];

    for (let i = 0; i < 7; i++) {
      stars.push(<span key={i} className={`unit-avatar-star unit-avatar-star-${i + 1}`} />);
    }

    return (
      <div className="App">
        <div className={"header"}>
          <div className={"left-side"}>
            <div className={"ally-code"}>
              <div className={"title"}>Enter your ally code to filter your equipped gear:</div>
              <div className={"search"}>
                <TextField
                  name="allycode"
                  placeholder={"Your ally code: 123456789"}
                  onChange={this.allyCodeChange}
                  value={this.state.allyCode}
                  type="number"
                  variant="filled"
                  className={"input"}
                />
                <Button className={"refresh"} onClick={this.refresh}>
                  <FontAwesomeIcon icon={faSyncAlt} />
                </Button>
              </div>
              <div className={"subtitle"}>
                <span>Last Updated:</span> {DisplayLastUpdated}
              </div>
            </div>
          </div>
          <Presets
            {...this.props}
            selectedLegendaries={this.state.selectedLegendaries}
            showLegendary={this.handleShowLegendary.bind(this)}
            LegendaryRequirements={this.props.LegendaryRequirements}
          />
        </div>
        <div className={"body"}>
          {HotUtilsError}
          <div className={"title"}>Select a character (or characters) to view the gear required:</div>

          <div className={"container padd10 character-wrapper"}>
            <div className={"unit unit-add"} onClick={this.handleModal}>
              <div className={"unit-avatar"}>
                <img src={"../img/add-character.png"} title={"Add a character"} alt={"Add_Character"} />
              </div>
              <div className={"unit-title"}>Add a character</div>
            </div>
            {this.state.selectedUnit &&
              this.state.selectedUnit.map((unit) => {
                return (
                  <div key={unit.id} className={"unit"} onClick={this.handleCharacterClick.bind(this, unit)}>
                    <div className={"unit-force-" + this.getForceAlignment(unit) + " unit-avatar"}>
                      <div className={"unit-avatar-portrait"}>
                        {unit.thumbnailName ? (
                          <img src={`//game-assets.swgoh.gg/textures/${unit.thumbnailName}.png`} alt={unit.name} />
                        ) : (
                          <img src={`//d3r3iyebi19x0f.cloudfront.net/u/${unit.id}.png`} alt={unit.name} />
                        )}
                      </div>
                      <span
                        className={"unit-avatar-gear" + (unit.gear === 13 ? 13 : "")}
                        style={{
                          backgroundImage:
                            unit.gear === 13
                              ? `url(${frameAtlasImg})`
                              : `url(https://api.hotutils.com/images/gear/g${Number(unit.gear)}.png)`
                        }}
                      />
                      {stars}
                      {unit.relics > 0 ? <span className="unit-avatar-relic">{Number(unit.relics)}</span> : null}
                    </div>
                    <div className={"unit-title"}>{unit.name}</div>
                  </div>
                );
              })}
            <button
              className={"clear-board"}
              type={"button"}
              title={"Remove all the character"}
              onClick={this.removeAllCharacters}
            >
              <FontAwesomeIcon icon={faUserTimes} />
            </button>
          </div>
          <div className={"container"}>
            <Accordion defaultExpanded={true} className={"accordion"}>
              <AccordionSummary
                className={"accordion-header"}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="relic-content"
                id="relic-header"
              >
                <div className={"title"}>Relics</div>
              </AccordionSummary>
              {RelicTable}
            </Accordion>
          </div>
          <div className={"container gear"} id={"gear"}>
            <ReactTooltip html={true} className={"tooltip"} />
            <Accordion defaultExpanded={true} className={"accordion"}>
              <AccordionSummary
                className={"accordion-header"}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="gear-content"
                id="gear-header"
              >
                <div className={"title"}>Gear</div>
              </AccordionSummary>
              {DisplayTable}
            </Accordion>
          </div>
        </div>

        <div className="download">
          <CSVLink data={this.state.dataToDownload} filename="data.csv" asyncOnClick={true} onClick={this.download}>
            <Button variant="contained">Download CSV</Button>
          </CSVLink>
        </div>

        <SideBar
          {...this.props}
          isExpanded={this.props.isExpanded}
          toggleExpanded={this.props.toggleExpanded}
          hideCompletedGear={this.handleHideCompletedGear.bind(this)}
          hideCraftedGear={this.handleCraftedGear.bind(this)}
          buyHDB={this.handleBuyHDB.bind(this)}
          batchUpdateSpendingNormalEnergy={this.batchUpdateSpendingNormalEnergy.bind(this)}
          batchUpdateSpendingFleetEnergy={this.batchUpdateSpendingFleetEnergy.bind(this)}
          toggleFleetColumnBattles={this.toggleFleetColumnBattles.bind(this)}
          toggleDSColumnBattles={this.toggleDSColumnBattles.bind(this)}
          toggleLSColumnBattles={this.toggleLSColumnBattles.bind(this)}
          toggleHighlightVisible={this.toggleHighlightVisible.bind(this)}
          toggleTokensColumnVisible={this.toggleTokensColumnVisible.bind(this)}
          toggleEnergyColumnVisible={this.toggleEnergyColumnVisible.bind(this)}
          togglelowGearVisible={this.togglelowGearVisible.bind(this)}
          toggleHighGearVisible={this.toggleHighGearVisible.bind(this)}
          toggleCompactMobile={this.toggleCompactMobile.bind(this)}
          toggleIDColumnVisible={this.toggleIDColumnVisible.bind(this)}
          isFleetColumnVisible={this.state.isFleetColumnVisible}
          isDSColumnVisible={this.state.isDSColumnVisible}
          isLSColumnVisible={this.state.isLSColumnVisible}
          isHighlightVisible={this.state.isHighlightVisible}
          isTokensColumnVisible={this.state.isTokensColumnVisible}
          isEnergyColumnVisible={this.state.isEnergyColumnVisible}
          isHighGearVisible={this.state.isHighGearVisible}
          isLowGearVisible={this.state.isLowGearVisible}
          isCompactMobileVisible={this.state.isCompactMobileVisible}
          isIDColumnVisible={this.state.isIDColumnVisible}
        />

        <ModalCharacter
          open={this.state.isModalOpen}
          onClose={this.handleModal}
          classes={this.props.classes}
          value={this.state.addedCharacter}
          options={this.getModalUnits()}
          onChange={this.addCharacter}
          optionLabel={(data) => `${data.name}`}
          optionValue={(data) => {
            return data.id;
          }}
          callbackfn={(t) => ({ value: t, label: t })}
          label={this.state.addedGearLevel}
          numbers={this.state.gearLevel}
          numbers1={this.state.relicLevel}
          label1={this.state.addedRelicLevel}
          onChange1={this.addGear}
          onChange2={this.addRelic}
          onClick={this.deleteCharacterModal}
          onClick1={this.saveModal}
          isEditing={this.state.isEditing}
        />
      </div>
    );
  }
}

export default withStyles(useStyles)(GearTable);
