import React, {Component} from "react";
import "./Home.scss";

import {setup} from 'axios-cache-adapter';

import GearTable from "../../components/GearTable/GearTable";

import {withStyles} from "@material-ui/core/styles";

const styles = theme => ({
	root: {
		flexGrow: 1
	}
});

class Home extends Component {
	constructor(props) {
		super(props);
		this.state = {
			recipes: [],
			units: [],
			equipment: [],
			missions: [],
			farmLocations: {},
			relicNames: {},
			relicRequirements: {}
		};
	}

	componentDidMount() {
		const api = setup({
			cache: {
				maxAge: 15 * 60 * 1000,
				exclude: {
					query: false,
				}
			}
		})

		api.get("/json/units.json?v=" + process.env.REACT_APP_VERSION).then(response => {
			const units = [];
			for (let x in response.data) {
				const unit = response.data[x];

				units.push({
					id: unit.baseId,
					name: unit.nameKey,
					forceAlignment: unit.forceAlignment,
					thumbnailName: unit.thumbnailName
				});
			}
			units.sort((a, b) => a.name.localeCompare(b.name));
			this.setState({units: units});
		});

		api.get("/json/equipmentList.json?v=" + process.env.REACT_APP_VERSION).then(response => {
			var equipment = [];
			var data = response.data;
			for (var z in data) {
				equipment[data[z].id] = data[z];
			}

			this.setState({equipment: equipment});
		});

		api.get("/json/recipeList.json?v=" + process.env.REACT_APP_VERSION).then(response => {
			var recipes = [];
			var data = response.data;
			for (var x in data) {
				if (data[x].type === 7) {
					recipes[data[x].id] = data[x];
				}
			}

			this.setState({recipes: recipes});
		});

		api.get("/json/missions.json?v=" + process.env.REACT_APP_VERSION).then(response => {
			this.setState({missions: response.data});
		});
		api.get("/json/farmLocations.json?v=" + process.env.REACT_APP_VERSION).then(response => {
			this.setState({farmLocations: response.data});
		});
		api.get("/json/hdb.json?v=" + process.env.REACT_APP_VERSION).then(response => {
			this.setState({hdb: response.data.data[0].inventoryItem});
		});
		api.get("/json/stores/cantinaCurrency.json?v=" + process.env.REACT_APP_VERSION).then(response => {
			this.setState({cantinaCurrency: response.data});
		});
		api.get("/json/stores/fleetArenaCurrency.json?v=" + process.env.REACT_APP_VERSION).then(response => {
			this.setState({fleetArenaCurrency: response.data});
		});
		api.get("/json/stores/get1Currency.json?v=" + process.env.REACT_APP_VERSION).then(response => {
			this.setState({get1Currency: response.data});
		});
		api.get("/json/stores/get2Currency.json?v=" + process.env.REACT_APP_VERSION).then(response => {
			this.setState({get2Currency: response.data});
		});
		api.get("/json/stores/get3Currency.json?v=" + process.env.REACT_APP_VERSION).then(response => {
			this.setState({get3Currency: response.data});
		});
		api.get("/json/stores/premiumCurrency.json?v=" + process.env.REACT_APP_VERSION).then(response => {
			this.setState({premiumCurrency: response.data});
		});
		api.get("/json/stores/guildCurrency.json?v=" + process.env.REACT_APP_VERSION).then(response => {
			this.setState({guildCurrency: response.data});
		});
		api.get("/json/stores/seasonsCurrency.json?v=" + process.env.REACT_APP_VERSION).then(response => {
			this.setState({seasonsCurrency: response.data});
		});
		api.get("/json/stores/shardCurrency.json?v=" + process.env.REACT_APP_VERSION).then(response => {
			this.setState({shardCurrency: response.data});
		});
		api.get("/json/stores/squadArenaCurrency.json?v=" + process.env.REACT_APP_VERSION).then(response => {
			this.setState({squadArenaCurrency: response.data});
		});
		api.get("/json/LegendaryRequirements.json?v=" + process.env.REACT_APP_VERSION).then(response => {
			this.setState({LegendaryRequirements: response.data});
		});
		api.get("/json/relicReqs.json?v=" + process.env.REACT_APP_VERSION).then(response => {
			this.setState({relicRequirements: response.data});
		});
		api.get("/json/relics.json?v=" + process.env.REACT_APP_VERSION).then(response => {
			this.setState({relicNames: response.data});
		});
		api.get("/json/stores/raid1Currency.json?v=" + process.env.REACT_APP_VERSION).then(response => {
			this.setState({raid1Currency: response.data});
		});
		api.get("/json/stores/raid2Currency.json?v=" + process.env.REACT_APP_VERSION).then(response => {
			this.setState({raid2Currency: response.data});
		});
		api.get("/json/stores/raid3Currency.json?v=" + process.env.REACT_APP_VERSION).then(response => {
			this.setState({raid3Currency: response.data});
		});
		// Use this code to get more store data, then copy and paste the object from the console into state.
		// axios.get("/json/store.json?v="+ process.env.REACT_APP_VERSION).then(response => {
		//   this.setState({ storeOne: response.data });
		// });
		// axios.get("/json/store2.json?v="+ process.env.REACT_APP_VERSION).then(response => {
		//   this.setState({ storeTwo: response.data });
		// });
		// axios.get("/json/store3.json?v="+ process.env.REACT_APP_VERSION).then(response => {
		//   this.setState({ storeThree: response.data });
		// });
		// axios.get("/json/store4.json?v="+ process.env.REACT_APP_VERSION).then(response => {
		//   this.setState({ storeFour: response.data });
		// });
		// axios.get("/json/store5.json?v="+ process.env.REACT_APP_VERSION).then(response => {
		//   this.setState({ storeFive: response.data });
		// });
		// axios.get("/json/store6.json?v="+ process.env.REACT_APP_VERSION).then(response => {
		//   this.setState({ storeSix: response.data });
		// });
	}

	render() {
		//Use this code to help populate the cost arrays.  Copy and paste the resulting array into the correct file in /json/
		// if (
		//   typeof this.state.storeTwo !== "undefined" &&
		//   typeof this.state.storeOne !== "undefined" &&
		//   typeof this.state.storeFour !== "undefined" &&
		//   typeof this.state.storeFive !== "undefined" &&
		//   typeof this.state.storeSix !== "undefined" &&
		//   typeof this.state.storeThree !== "undefined"
		// ) {
		//   var currencyType = "PREMIUM"
		//   var costs = [];
		//   var storeOneShipments = this.state.storeOne.shipment;
		//   var storeTwoShipments = this.state.storeTwo.shipment;
		//   var storeThreeShipments = this.state.storeThree.shipment;
		//   var storeFourShipments = this.state.storeFour.shipment;
		//   var storeFiveShipments = this.state.storeFive.shipment;
		//   var storeSixShipments = this.state.storeSix.shipment;

		//   for (let store of storeOneShipments) {
		//     for (let type of store.shipmentItem) {
		//       if (type.purchaseCost.id === currencyType) {
		//         if (costs.findIndex(i => i.id === type.previewItem.id) == -1) {
		//           costs.push({
		//             id: type.previewItem.id,
		//             cost:
		//               type.purchaseCost.maxQuantity / type.previewItem.minQuantity
		//           });
		//         }
		//       }
		//     }
		//   }
		//   for (let store of storeTwoShipments) {
		//     for (let type of store.shipmentItem) {
		//       if (type.purchaseCost.id === currencyType) {
		//         if (costs.findIndex(i => i.id === type.previewItem.id) == -1) {
		//           costs.push({
		//             id: type.previewItem.id,
		//             cost:
		//               type.purchaseCost.maxQuantity / type.previewItem.minQuantity
		//           });
		//         }
		//       }
		//     }
		//   }
		//   for (let store of storeThreeShipments) {
		//     for (let type of store.shipmentItem) {
		//       if (type.purchaseCost.id === currencyType) {
		//         if (costs.findIndex(i => i.id === type.previewItem.id) == -1) {
		//           costs.push({
		//             id: type.previewItem.id,
		//             cost:
		//               type.purchaseCost.maxQuantity / type.previewItem.minQuantity
		//           });
		//         }
		//       }
		//     }
		//   }
		//   for (let store of storeFourShipments) {
		//     for (let type of store.shipmentItem) {
		//       if (type.purchaseCost.id === currencyType) {
		//         if (costs.findIndex(i => i.id === type.previewItem.id) == -1) {
		//           costs.push({
		//             id: type.previewItem.id,
		//             cost:
		//               type.purchaseCost.maxQuantity / type.previewItem.minQuantity
		//           });
		//         }
		//       }
		//     }
		//   }
		//   for (let store of storeFiveShipments) {
		//     for (let type of store.shipmentItem) {
		//       if (type.purchaseCost.id === currencyType) {
		//         if (costs.findIndex(i => i.id === type.previewItem.id) == -1) {
		//           costs.push({
		//             id: type.previewItem.id,
		//             cost:
		//               type.purchaseCost.maxQuantity / type.previewItem.minQuantity
		//           });
		//         }
		//       }
		//     }
		//   }
		//   for (let store of storeSixShipments) {
		//     for (let type of store.shipmentItem) {
		//       if (type.purchaseCost.id === currencyType) {
		//         if (costs.findIndex(i => i.id === type.previewItem.id) == -1) {
		//           costs.push({
		//             id: type.previewItem.id,
		//             cost:
		//               type.purchaseCost.maxQuantity / type.previewItem.minQuantity
		//           });
		//         }
		//       }
		//     }
		//   }
		//   console.log(storeFiveShipments);
		//   console.log(costs);
		// }

		return (
			<div className={'home' + (this.props.isExpanded ? ' collapsed' : '')}>
				<GearTable
					{...this.props}
					equipment={this.state.equipment}
					recipes={this.state.recipes}
					units={this.state.units}
					missions={this.state.missions}
					hdb={this.state.hdb}
					farmLocations={this.state.farmLocations}
					firebaseUser={this.props.firebaseUser}
					allycode={this.props.allycode}
					premiumCurrency={this.state.premiumCurrency} //PREMIUM
					fleetArenaCurrency={this.state.fleetArenaCurrency} //PVP_SHIP_CURRENCY
					get1Currency={this.state.get1Currency} //TERRITORY_BATTLE_CURRENCY
					get2Currency={this.state.get2Currency} //TERRITORY_BATTLE_CURRENCY_02
					get3Currency={this.state.get3Currency}
					raid1Currency={this.state.raid1Currency}
					raid2Currency={this.state.raid2Currency}
					raid3Currency={this.state.raid3Currency}
					guildCurrency={this.state.guildCurrency} //GUILD_CURRENCY
					seasonsCurrency={this.state.seasonsCurrency} //SEASONS_CURRENCY
					shardCurrency={this.state.shardCurrency} //SHARD_CURRENCY
					// squadArenaCurrency={this.state.squadArenaCurrency}
					selectedCharacter={this.props.character}
					LegendaryRequirements={this.state.LegendaryRequirements}
					relicRequirements={this.state.relicRequirements}
					relicNames={this.state.relicNames}
				/>
			</div>
		);
	}
}

export default withStyles(styles)(Home);
