import React from "react";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";

import "./GearOptions.scss";
import "../../styles/modules/checkbox.scss"
import Input from "@material-ui/core/Input";


class GearOptions extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			accountForCrafted: false,
			purchaseHDB: false,
			hide_completed: false,
		};
	}

	handleCompletedChange = (e) => {
		const hide_completed = !this.state.hide_completed;
		this.setState({hide_completed: hide_completed}, () => {
			this.props.hideCompletedGear(this.state.hide_completed);
			localStorage.setItem("hide_completed", this.state.hide_completed);
		});
	};
	handleCraftedChange = () => {
		const accountForCrafted = !this.state.accountForCrafted;
		this.setState({accountForCrafted}, () => {
			this.props.hideCraftedGear(this.state.accountForCrafted);
			localStorage.setItem("accountForCrafted", this.state.accountForCrafted);
		});
	};
	handleHDBChange = () => {
		const purchaseHDB = !this.state.purchaseHDB;
		this.setState({purchaseHDB}, () => {
			this.props.buyHDB(this.state.purchaseHDB);
			localStorage.setItem("purchaseHDB", this.state.purchaseHDB);
		});
	};

	componentDidMount() {
		if (localStorage.getItem("hide_completed")) {
			this.setState(
				{hide_completed: JSON.parse(localStorage.getItem("hide_completed"))},
				() => {
					this.props.hideCompletedGear(this.state.hide_completed);
				}
			);
		}
		if (localStorage.getItem("accountForCrafted")) {
			this.setState(
				{
					accountForCrafted: JSON.parse(
						localStorage.getItem("accountForCrafted")
					),
				},
				() => {
					this.props.hideCraftedGear(this.state.accountForCrafted);
				}
			);
		}
		if (localStorage.getItem("purchaseHDB")) {
			this.setState(
				{purchaseHDB: JSON.parse(localStorage.getItem("purchaseHDB"))},
				() => {
					this.props.buyHDB(this.state.purchaseHDB);
				}
			);
		}
	}

	render() {
		const handleFocus = (event) => event.target.select();

		return (
			<div className={'gear-option'}>
				<FormGroup row>
					<FormControlLabel
						className={'custom-cbox'}
						control={
							<Checkbox
								checked={this.state.hide_completed}
								onChange={this.handleCompletedChange}
								color="primary"
							/>
						}
						label="Hide Completed Gear"
					/>
					<FormControlLabel
						className={'custom-cbox'}
						control={
							<Checkbox
								checked={this.state.accountForCrafted}
								onChange={this.handleCraftedChange}
								color="primary"
							/>
						}
						label="Remove Crafted Gear"
					/>
					<FormControlLabel
						className={'custom-cbox'}
						control={
							<Checkbox
								checked={this.state.purchaseHDB}
								onChange={this.handleHDBChange}
								color="primary"
							/>
						}
						label="After purchasing HDB"
					/>
					<FormControlLabel
						className={'custom-cbox'}
						control={
							<Checkbox
								checked={!this.props.isFleetColumnVisible}
								onChange={this.props.toggleFleetColumnBattles}
								color="primary"
							/>
						}
						label="Show Fleet Battles Column"
					/>
					<FormControlLabel
						className={'custom-cbox'}
						control={
							<Checkbox
								checked={!this.props.isLSColumnVisible}
								onChange={this.props.toggleLSColumnBattles}
								color="primary"
							/>
						}
						label="Show LS Battles Column"
					/>
					<FormControlLabel
						className={'custom-cbox'}
						control={
							<Checkbox
								checked={!this.props.isDSColumnVisible}
								onChange={this.props.toggleDSColumnBattles}
								color="primary"
							/>
						}
						label="Show DS Battles Column"
					/>
					<FormControlLabel
						className={'custom-cbox'}
						control={
							<Checkbox
								checked={this.props.isCompactMobileVisible}
								onChange={this.props.toggleCompactMobile}
								color="primary"
							/>
						}
						label="Compact Mobile View"
					/>
					<FormControlLabel
						className={'custom-cbox'}
						control={
							<Checkbox
								checked={!this.props.isHighlightVisible}
								onChange={this.props.toggleHighlightVisible}
								color="primary"
							/>
						}
						label="Remove highlight colours from the tables"
					/>
					<FormControlLabel
						className={'custom-cbox'}
						control={
							<Checkbox
								checked={!this.props.isTokensColumnVisible}
								onChange={this.props.toggleTokensColumnVisible}
								color="primary"
							/>
						}
						label="Hide the token columns"
					/>
					<FormControlLabel
						className={'custom-cbox'}
						control={
							<Checkbox
								checked={!this.props.isIDColumnVisible}
								onChange={this.props.toggleIDColumnVisible}
								color="primary"
							/>
						}
						label="Show ID Column"
					/>
					<FormControlLabel
						className={'custom-cbox'}
						control={
							<Checkbox
								checked={!this.props.isEnergyColumnVisible}
								onChange={this.props.toggleEnergyColumnVisible}
								color="primary"
							/>
						}
						label="Hide the Energy columns"
					/>
					<FormControlLabel
						className={'custom-cbox'}
						control={
							<Checkbox
								checked={!this.props.isLowGearVisible}
								onChange={this.props.togglelowGearVisible}
								color="primary"
							/>
						}
						label="Hide Gear 1-6"
					/>
					<FormControlLabel
						className={'custom-cbox'}
						control={
							<Checkbox
								checked={!this.props.isHighGearVisible}
								onChange={this.props.toggleHighGearVisible}
								color="primary"
							/>
						}
						label="Hide Gear 7-11"
					/>
					<div className="title">Global Fleet Energy:</div>
					<Input
						id={`fleet-energy}`}
						label="Fleet Energy"
						type="number"
						className="input-energy"
						onFocus={handleFocus}
						defaultValue={645}
						onChange={this.props.batchUpdateSpendingFleetEnergy}
						inputProps={{
							"aria-label": "Qty",
						}}
					/>
					<div className="title">Global Normal Energy:</div>
					<Input
						id={`fleet-energy}`}
						label="Normal Energy"
						type="number"
						className="input-energy"
						onFocus={handleFocus}
						defaultValue={735}
						onChange={this.props.batchUpdateSpendingNormalEnergy}
						inputProps={{
							"aria-label": "Qty",
						}}
					/>
				</FormGroup>
			</div>
		);
	}
}

export default GearOptions;
