import React from 'react';
import ReactDOM from 'react-dom';

import './index.scss';
import './styles/config.scss';
import App from './containers/App/App';
import { unregister } from './registerServiceWorker';

unregister();

ReactDOM.render(
    <App />,
    document.getElementById('root')
);

