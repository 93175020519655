import Rebase from 're-base';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/database';
import 'firebase/auth';

var app = firebase.initializeApp({
  apiKey: 'AIzaSyAhiA90g5cgpTeOAsr1MbAemaAuuLkpz3w',
  authDomain: 'swgoh-gear.firebaseapp.com',
  databaseURL: 'https://swgoh-gear.firebaseio.com',
  projectId: 'swgoh-gear'
});

// const settings = {
//   timestampsInSnapshots: true
// };

var db = firebase.database(app);
// db.settings(settings);
var base = Rebase.createClass(db);

export default base;