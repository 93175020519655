import React from 'react';
import Typography from '@material-ui/core/Typography';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import './SideBar.scss';
import GearOptions from "../GearTable/GearOptions";
import {faCog} from "@fortawesome/pro-duotone-svg-icons";
import {faTimes} from "@fortawesome/pro-solid-svg-icons";


class SideBar extends React.PureComponent{
    constructor(props) {
        super(props);
    }
      
    render() {
        return (
        <div className={'sidebar' + (this.props.isExpanded ? '' : ' collapsed')}>
            <div className={'gear-option-btn'} onClick={
                () => {
                    this.props.toggleExpanded();
                }}
                >
                <div className={'gear-option-icon' + (this.props.isExpanded ? '' : ' spin')}>
                    <FontAwesomeIcon icon={this.props.isExpanded ? faTimes : faCog} />
                </div>
            </div>
            <div className={'wrapper'}>
                <div className={'title'}>
                    <Typography className={'txt'}>
                      Gear Option
                    </Typography>
                </div>
                <GearOptions
                    {...this.props}
                    hideCompletedGear={this.props.hideCompletedGear}
                    hideCraftedGear={this.props.hideCraftedGear}
                    buyHDB={this.props.buyHDB}
                    isGearVisible={false}
                    batchUpdateSpendingFleetEnergy={this.props.batchUpdateSpendingFleetEnergy}
                    batchUpdateSpendingNormalEnergy={this.props.batchUpdateSpendingNormalEnergy}
                    toggleFleetColumnBattles={this.props.toggleFleetColumnBattles}
                    toggleDSColumnBattles={this.props.toggleDSColumnBattles}
                    toggleLSColumnBattles={this.props.toggleLSColumnBattles}
                    toggleHighlightVisible={this.props.toggleHighlightVisible}
                    toggleTokensColumnVisible={this.props.toggleTokensColumnVisible}
                    toggleEnergyColumnVisible={this.props.toggleEnergyColumnVisible}
                    togglelowGearVisible={this.props.togglelowGearVisible}
                    toggleHighGearVisible={this.props.toggleHighGearVisible}
                    toggleCompactMobile={this.props.toggleCompactMobile}
                    toggleIDColumnVisible={this.props.toggleIDColumnVisible}
                    isFleetColumnVisible={this.props.isFleetColumnVisible}
                    isDSColumnVisible={this.props.isDSColumnVisible}
                    isLSColumnVisible={this.props.isLSColumnVisible}
                    isHighlightVisible={this.props.isHighlightVisible}
                    isTokensColumnVisible={this.props.isTokensColumnVisible}
                    isEnergyColumnVisible={this.props.isEnergyColumnVisible}
                    isLowGearVisible={this.props.isLowGearVisible}
                    isHighGearVisible={this.props.isHighGearVisible}
                    isCompactMobileVisible={this.props.isCompactMobileVisible}
                    isIDColumnVisible={this.props.isIDColumnVisible}
                />
            </div>
        </div>
      );
    }
}

export default (SideBar);